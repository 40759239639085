import 'modernize'
import 'plugins'
import 'jquery'
import lax from 'lax.js'

window.minZero = function(d) {
  return Math.max(d, 0);
}

window.onload = function () {
  lax.setup() // init

  const updateLax = () => {
    lax.update(window.scrollY)
    window.requestAnimationFrame(updateLax)
  }

  window.requestAnimationFrame(updateLax)

  let w = window.innerWidth;
  window.addEventListener("resize", function () {
    if (w !== window.innerWidth) {
      w = window.innerWidth;
      lax.updateElements();
    }
  });

  let scrollSnapTimer;
  function scrollSnapHandler() {
    console.log("scroll");
    // scrolling should reset our scroll-snap timer
    if (scrollSnapTimer) {
      // we only want to call a timeout once after scrolling..
      clearTimeout(scrollSnapTimer);
      console.log("scroll snap disable");
    }
    scrollSnapTimer = window.setTimeout(scrollSnap, 300);
  };

  // check for scroll-snap once every repaint because there are just too many scroll events
  function scrollSnap() {
      for (const p of document.getElementsByClassName("page")) {
        if (Math.abs(p.getBoundingClientRect().top) < window.innerHeight / 3) {
          window.scrollTo({
              left: 0,
              top: p.offsetTop,
              behavior: "smooth"
            }
          );
        }
      }
  }

  window.addEventListener("scroll", scrollSnapHandler);
  // one initial scroll snap for reloads of the page
  scrollSnapHandler();
}
